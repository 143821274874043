// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-diffused-posterization-index-tsx": () => import("./../../../src/pages/diffused-posterization/index.tsx" /* webpackChunkName: "component---src-pages-diffused-posterization-index-tsx" */),
  "component---src-pages-dynamic-2-d-camera-index-tsx": () => import("./../../../src/pages/dynamic-2d-camera/index.tsx" /* webpackChunkName: "component---src-pages-dynamic-2-d-camera-index-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mesh-slicer-index-tsx": () => import("./../../../src/pages/mesh-slicer/index.tsx" /* webpackChunkName: "component---src-pages-mesh-slicer-index-tsx" */),
  "component---src-pages-spiderverse-index-tsx": () => import("./../../../src/pages/spiderverse/index.tsx" /* webpackChunkName: "component---src-pages-spiderverse-index-tsx" */)
}

